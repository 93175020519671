<template>
    <section class="zc-calendario w-100 overflow-auto scroll-old mt-4">
        <div class="col-11">
            <FullCalendar :options="calendarOptions" class="fullcalendar-styles" />
        </div>
    </section>
</template>
<script>
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
    data(){
        return{
            inputSearch:'',
            mostrarRequierenPago: false,
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                locale: 'es',
                selectable: true,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                },
                buttonText:{
                    today: 'Hoy'
                },
                dateClick: function(info) {
                    alert('clicked ' + info.dateStr);
                },
                eventClick: function(info) {
                    // window.open(`/zonas/reservas/${info.event.id}`, '_self')
                    window.open('/zonas/reservas/id', '_self')
                },
                dayMaxEvents: true,
                eventDisplay: 'list-item',
                events: [
                    {   
                        id: 0,
                        title: 'Salida',
                        start: '2021-10-01',
                        className: 'pendiente',
                    },
                    {
                        id:1,
                        title: 'Almuerzo',
                        start: '2021-10-01',
                        className: 'reservado'
                    },
                    {
                        title: 'halloween',
                        start: '2021-10-31',
                        className: 'actual',
                    },
                    {
                        title: 'Evento naranja',
                        start: '2021-10-04',
                        className: 'pendiente'
                    },
                    {
                        title: 'All Day Event',
                        start: '2021-10-10',
                        className: 'reservado'
                    },
                    {
                        title: 'All Day Event 2',
                        start: '2021-10-05',
                        className: 'actual'
                    },
                ]
            },
        }
    },
    methods:{
        verListado(){},
        verCalendario(){},
        handleDateClick(){
            console.log('clicked');
        }
    }
}
</script>
<style lang="scss" scoped>
.zc-calendario{
    height: calc(100vh - 200px);
    
}
</style>